import { default as merge } from "lodash/merge";

import { Brand, DEFAULT_BRAND } from "./Brand";

export default function getBrandFromEnv(): Brand {
  let brand = DEFAULT_BRAND;

  if (process.env.REACT_APP_BRAND) {
    try {
      brand = merge(brand, JSON.parse(process.env.REACT_APP_BRAND));
    } catch (e) {
      console.error("Error while loading brand from environment:", e);
    }
  }

  if (window.BRAND) {
    try {
      brand = merge(brand, window.BRAND);
    } catch (e) {
      console.error("Error while loading brand from environment:", e);
    }
  }

  return brand;
}
