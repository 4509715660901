import { QueryKey, useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";

import { useApi } from "Domain/Api";
import { Consent, ContactFunction, UserProfile } from "Types/User";

export function useMeQuery(options?: UseQueryOptions<any, Error, any, QueryKey>) {
  const api = useApi();

  return useQuery<UserProfile, Error>(
    api.getQueryKey(["me"]),
    () => api.get(`user/v1/me`).then((resp) => resp.json()),
    options,
  );
}

export function useFunctionsQuery() {
  const api = useApi();

  return useQuery<ContactFunction[], Error>(api.getQueryKey(["functions"]), () =>
    api.get(`user/v1/contact_functions`).then((resp) => resp.json()),
  );
}

export function useConsentsQuery() {
  const api = useApi();

  return useQuery<Consent[], Error>(api.getQueryKey(["consents"]), () =>
    api.get(`user/v1/consents`).then((resp) => resp.json()),
  );
}

export function useLanguagesQuery(language: string) {
  const api = useApi();

  return useQuery<any, Error>(api.getQueryKey(["languages", language]), () =>
    api.get(`user/v1/languages`).then((resp) => resp.json()),
  );
}

export function useResetPasswordQuery() {
  /*
  TODO: some refactoring to make usage more clear.
  - rename to *Mutation to make obvious difference between data retrieval and server side-effect queries
  - but, this should be done after checking it has the same interface as useMutation, otherwise, it will be confusing
    (maybe not as much as having it called *Query)
  - content of function looks like an asynchronous delegation but the Promise based code is hard to read. Let's see if
    we can make it simpler.
   */
  const api = useApi();

  const resetPasswordMutation = useMutation(() => api.post(`user/v1/me/reset_password`));

  return () => {
    return new Promise<Response>((resolve, reject) => {
      resetPasswordMutation.mutate(undefined, {
        onSuccess: (res) => resolve(res),
        onError: async (e) => {
          reject(e);
        },
      });
    });
  };
}

export function useEditMeQuery() {
  const api = useApi();
  const queryClient = useQueryClient();

  const editMeMutation = useMutation((data) => api.patch(`user/v1/me`, { body: JSON.stringify(data) }));

  return (data) => {
    return new Promise<Response>((resolve, reject) => {
      editMeMutation.mutate(data, {
        onSuccess: async (res) => {
          const me = await res.json();
          queryClient.setQueryData(api.getQueryKey(["me"]), {
            ...me,
          });
          resolve(res);
        },
        onError: async (e) => {
          reject(e);
        },
      });
    });
  };
}
