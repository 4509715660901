import { RefreshableQueryOptions } from "Context/SynchronizationContext";
import { useApi } from "Domain/Api";
import { useRefreshableQuery } from "Hooks";
import { FileProfile } from "Types/File";

export default function useProfileQuery(options: RefreshableQueryOptions = { showSynchronizationStatus: false }) {
  const api = useApi();

  return useRefreshableQuery<FileProfile>(
    api.getQueryKey(["profile"]),
    () => api.get(`file/v1/profile`).then((resp) => resp.json()),
    options,
  );
}
