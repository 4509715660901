import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import Help from "Components/Common/Container/Help";
import Logo from "Components/Common/Logo";
import { BRAND } from "Settings";

const OnboardingContainer = styled.div`
  ${tw`flex flex-row h-screen`}
`;

interface OnboardingLayoutProps {
  withHelpFooter?: boolean;
}

const OnboardingLayout = ({ children, withHelpFooter }: PropsWithChildren<OnboardingLayoutProps>) => {
  return (
    <OnboardingContainer>
      <div className="lg:w-1/3 w-full login flex flex-col overflow-auto">
        <div className={`flex items-center justify-center px-2 py-4`}>
          <Link to="/">
            <Logo className={`h-6 lg:h-12 my-12`} />
          </Link>
        </div>
        <div className="grow flex flex-col p-6 lg:p-12 pt-0">{children}</div>
        {withHelpFooter && <Help />}
      </div>
      <div className="lg:w-2/3 hidden lg:block image" style={{ backgroundImage: `url(${BRAND.images.welcome})` }} />
    </OnboardingContainer>
  );
};

export default OnboardingLayout;
