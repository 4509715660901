import styled from "styled-components";

const StyledItem = styled.span`
  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }

  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  display: inline-block;
  height: 12px;
  min-width: 12px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  z-index: 1;
  background: ${(props) => props.theme.alert["100"]};
  color: ${(props) => props.theme.white};

  transform: scale(1);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse-red 2s infinite;
`;

interface PropsType {
  children?: React.ReactNode;
  className?: string;
}

const NotificationBagde = (props: PropsType) => {
  return <StyledItem className={props.className}>{props.children}</StyledItem>;
};

export default NotificationBagde;
