import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import { TrackedLink } from "Components/Common/Links";
import NotificationBagde from "Components/Common/NotificationBagde";
import { useProfileQuery } from "Domain/Profile";
import { isEventMarketOnly } from "Domain/Profile/Utils";

const StyledMenu = styled.div`
  section {
    height: 48px;
    background: ${(props) => props.theme.white};
    ${tw`shadow-lg`}
    color: ${(props) => props.theme.gray["400"]};
    font-weight: 600;
  }

  .tabs {
    height: 48px;

    .tab {
      border-top: 1px solid ${(props) => props.theme.gray["200"]};
    }

    .tab:not(:last-child) {
      border-right: 1px solid ${(props) => props.theme.gray["200"]};
    }

    .tab:hover,
    .tab:focus {
      outline: none;
      color: ${(props) => props.theme.primary.default};

      i {
        background-color: ${(props) => props.theme.primary.default};
      }
    }

    .tab.active {
      border-top: 2px solid ${(props) => props.theme.primary.default};
      color: ${(props) => props.theme.primary.default};

      i {
        background-color: ${(props) => props.theme.primary.default};
      }
    }
  }

  i {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: ${(props) => props.theme.gray["400"]};
  }

  .ico-home {
    mask-image: url(/assets/svg/ico-home.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  .ico-intervention {
    mask-image: url(/assets/svg/ico-intervention.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  .ico-files {
    mask-image: url(/assets/svg/ico-files.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  .ico-invoice {
    mask-image: url(/assets/svg/ico-invoice.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  .ico-contact {
    mask-image: url(/assets/svg/ico-contact.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  .menu-separator {
    position: absolute;
    width: 100%;
    height: 24px;
    bottom: 48px;
    background: radial-gradient(50.19% 33.33% at 49.81% 100%, rgba(63, 63, 70, 0.15) 0%, rgb(247, 247, 249, 0) 100%);
  }

  .menu-badge {
    top: -0.5rem;
    height: 16px;
    width: 16px;
    animation: none;
    border: 2px solid ${(props) => props.theme.white};
    font-size: 10px;
    line-height: 13px;
  }
`;

const MenuMobile = () => {
  const { t } = useTranslation("menu");
  const location = useLocation();
  const profileQuery = useProfileQuery();

  return (
    <StyledMenu className="shrink-0 w-full">
      <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10">
        <div className="menu-separator" />
        <div className="flex justify-between tabs">
          <TrackedLink
            to="/"
            className={`w-full justify-center inline-block text-center pt-1 pb-1 tab ${
              location.pathname === "/" ? "active" : ""
            }`}
          >
            <i className="ico-home" />
            <span className="block text-xs">{t("menu:menu.home")}</span>
          </TrackedLink>
          {profileQuery.isSuccess && profileQuery.data.interventionsStats.total > 0 ? (
            <TrackedLink
              to={isEventMarketOnly(profileQuery.data?.markets) ? "/interventions/events" : "/interventions"}
              className={`w-full justify-center inline-block text-center pt-1 pb-1 tab ${
                /^\/interventions*/.test(location.pathname) ? "active" : ""
              }`}
            >
              <i className="ico-intervention" />
              <span className="block text-xs">{t("menu:menu.interventions")}</span>
            </TrackedLink>
          ) : null}
          {profileQuery.isSuccess && profileQuery.data.counters.files.total > 0 ? (
            <TrackedLink
              to={`/files${
                profileQuery.isSuccess &&
                profileQuery.data.hasInvoicePermission &&
                profileQuery.data.counters.quotes.active > 0
                  ? "/quotes"
                  : ""
              }`}
              className={`w-full justify-center inline-block text-center pt-1 pb-1 tab relative ${
                /^\/files*/.test(location.pathname) ? "active" : ""
              }`}
            >
              {profileQuery.isSuccess &&
                profileQuery.data.hasInvoicePermission &&
                profileQuery.data.counters.quotes.active > 0 && (
                  <NotificationBagde className="menu-badge">{profileQuery.data.counters.quotes.new}</NotificationBagde>
                )}
              <i className="ico-files" />
              <span className="block text-xs">{t("menu:menu.files")}</span>
            </TrackedLink>
          ) : null}
          {profileQuery.isSuccess &&
          profileQuery.data.hasInvoicePermission &&
          profileQuery.data.counters.invoices.total > 0 ? (
            <TrackedLink
              to="/invoices"
              className={`w-full justify-center inline-block text-center pt-1 pb-1 tab ${
                /^\/invoices*/.test(location.pathname) ? "active" : ""
              }`}
            >
              <i className="ico-invoice" />
              <span className="block text-xs">{t("menu:menu.invoices")}</span>
            </TrackedLink>
          ) : null}
          <TrackedLink
            to="/contact"
            className={`w-full justify-center inline-block text-center pt-1 pb-1 tab ${
              /^\/contact*/.test(location.pathname) ? "active" : ""
            }`}
          >
            <i className="ico-contact" />
            <span className="block text-xs">{t("menu:menu.contact")}</span>
          </TrackedLink>
        </div>
      </section>
    </StyledMenu>
  );
};

export default MenuMobile;
