import { useContext, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";

import SynchronizationContext from "Context/SynchronizationContext";
import { REFETCH_INTERVAL } from "Settings";

function useRefreshableInfiniteQuery<T>(key, callback, options?: any) {
  const [isSynchronized, setIsSynchronized] = useState(true);
  const synchronizationContext = useContext(SynchronizationContext);
  const result = useInfiniteQuery<T>(key, callback, {
    ...options,
    refetchInterval: !isSynchronized ? REFETCH_INTERVAL : undefined,
  });

  useEffect(() => {
    const meta = result.isSuccess && result.data.pages.length ? (result.data.pages[0] as any).meta : null;
    if (result.isSuccess && meta) {
      if (meta.synchronization.fresh && !isSynchronized) {
        setIsSynchronized(true);
      } else if (!meta.synchronization.fresh && isSynchronized) {
        setIsSynchronized(false);
      }
    }
  }, [result.isSuccess, result.data, isSynchronized]);

  useEffect(() => {
    if (options && options.showSynchronizationStatus) {
      synchronizationContext.setIsSynchronized(isSynchronized);

      return () => synchronizationContext.setIsSynchronized(true);
    }
  }, [isSynchronized, options, synchronizationContext]);

  return { ...result, isSynchronized };
}

export default useRefreshableInfiniteQuery;
