import PiwikPro from "@piwikpro/react-piwik-pro";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";

import {
  PIWIK_ANALYTICS_CONTAINERS_ENDPOINT,
  PIWIK_ANALYTICS_ID,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  USE_PIWIK_ANALYTICS,
} from "Settings";
import "Utils/I18n";

import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

declare global {
  interface Window {
    BRAND?: any;
    GOOGLE_ANALYTICS_ID?: string;
    GOOGLE_MAPS_APIKEY?: string;
    PAGE_SIZE?: number;
    PIWIK_ANALYTICS_ID?: string;
    SENTRY_DSN?: string;
    SENTRY_ENVIRONMENT?: string;
    USE_GOOGLE_ANALYTICS?: string;
    USE_PIWIK_ANALYTICS?: string;
    VERSION?: string;
    google?: any;
  }
}

if (SENTRY_DSN) {
  const _options = {
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: window.VERSION || "dev",
    allowUrls: [window.location.origin],
    denyUrls: [/piwik\.pro/i],
  };

  if (SENTRY_ENVIRONMENT) {
    _options["environment"] = SENTRY_ENVIRONMENT;
  }

  Sentry.init(_options);
}

if (USE_PIWIK_ANALYTICS && PIWIK_ANALYTICS_ID) {
  PiwikPro.initialize(PIWIK_ANALYTICS_ID, PIWIK_ANALYTICS_CONTAINERS_ENDPOINT);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
