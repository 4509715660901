const DefaultTheme = {
  transparent: "transparent",
  current: "currentColor",
  white: "#FFFFFF",
  dark: "#2D2D2D",
  primary: {
    light: "#CCE8E9",
    default: "#008B92",
    hover: "#005358",
    pressed: "#001C1D",
    50: "#E6F3F4",
    100: "#CDE9EA",
    200: "#B3DCDE",
    300: "#80C5C8",
    400: "#4DAEB3",
    500: "#008D94",
    600: "#005358",
  },
  promo: {
    300: "#E07801",
  },
  button: {
    primary: {
      light: "#00181A",
      default: "#197279",
      hover: "#0E474C",
      pressed: "#050427",
    },
    secondary: {
      hover: "#E5E7EB",
    },
  },
  gray: {
    50: "#FCFCFC",
    100: "#F7F7F9",
    200: "#EDEDEE",
    300: "#D4D4D8",
    400: "#A1A1AA",
    500: "#71717A",
    600: "#52525B",
    700: "#3F3F46",
    800: "#111121",
  },
  alert: {
    50: "#FEE2E2",
    100: "#EF4444",
    200: "#7F1D1D",
  },
  information: {
    50: "#F1F5F9",
    100: "#B6C0CE",
    200: "#1F2937",
  },
  processing: {
    50: "#EFF6FF",
    100: "#8CD2DD",
    200: "#2C5081",
  },
  success: {
    50: "#DEEF7B",
    100: "#A7C823",
    200: "#3B5225",
  },
  reclamation: {
    50: "#FFFBEB",
    100: "#F2D719",
    200: "#311A00",
  },
  subtle: {
    light: "#E5E7EB",
    dark: "#1F2937",
  },
};

export default DefaultTheme;
