import { useAuthentication } from "Context/AuthenticationContext";
import { Step5Response } from "Types/Cart";

// eslint-disable-next-line @typescript-eslint/ban-types
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

const useTunnel = () => {
  const { token } = useAuthentication();
  const tunnelName = `${token}:tunnel`;

  const getTunnel = (): DeepPartial<Step5Response> => JSON.parse(localStorage.getItem(tunnelName) ?? "{}");
  const setTunnel = (tunnelStorage: DeepPartial<Step5Response>) =>
    localStorage.setItem(tunnelName, JSON.stringify(tunnelStorage));
  const removeTunnel = () => localStorage.removeItem(tunnelName);

  return {
    getTunnel,
    setTunnel,
    removeTunnel,
    tunnelName,
  };
};

export default useTunnel;
