import { Link } from "react-router-dom";

import { AnalyticsEventType, sendAnalyticsClickEvent, sendAnalyticsEvent } from "Utils/Analytics";

export const TrackedLink = (props) => {
  const { onClick, ...moreProps } = props;
  const onClickWithTracker = (e) => {
    sendAnalyticsClickEvent({ classes: props.className, id: props.id, url: props.to });

    let piwikAction: AnalyticsEventType = "undefined";

    switch (props.to) {
      case "/":
        piwikAction = "home";
        break;
      case "/files":
        piwikAction = "files";
        break;
      case "/files/quotes":
        piwikAction = "quotes";
        break;
      case "/interventions":
        piwikAction = "interventions";
        break;
      case "/invoices":
        piwikAction = "invoices";
        break;
      case "/contact":
        piwikAction = "contact";
        break;
      case "/account":
        piwikAction = "account";
        break;
      case "/login":
        piwikAction = "connection";
        break;
    }
    sendAnalyticsEvent("click", piwikAction ?? "undefined", { piwikDescription: "menu" }, true);

    if (onClick) {
      return onClick(e);
    }
  };

  return <Link {...moreProps} onClick={onClickWithTracker} />;
};
