import { Address } from "Types/Cart";

export interface FullAddress extends Address {
  zipCode: string;
  city?: string;
}

// @XXX: cf. https://stackoverflow.com/a/53739792
export function flattenObject(ob, path = "") {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null && !Object.keys(ob).includes("code")) {
      const flatObject = flattenObject(ob[i], i);

      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x) || x === "message") continue;

        if (x === "code") {
          toReturn[i] = {
            ...flatObject,
            code: `${path}${Number(i) === 0 ? "" : `.${i}`}`,
          };
        } else {
          toReturn[i + "." + x] = flatObject[x];
        }
      }
    } else {
      toReturn[i] = ob[i];
    }
  }

  return toReturn;
}

const Format = {
  lng: "fr",
  locale: (lng) => (Format.lng = lng),
  toLocalNumber: (value) => value.toLocaleString(Format.lng),
  address: (address: Partial<FullAddress>) => ({
    line1: `${address?.header ? `${address.header} ` : ""}${address?.number ? `${address.number} ` : ""}${
      address?.street ? `${address?.street}` : ""
    }`.trim(),
    line2: `${address?.zipCode ? `${address.zipCode} ` : ""}${address?.city ? address.city : ""}`.trim(),
  }),
  errorMessages: (errorMessages: Record<string, { message: string; code: string }>) => {
    const messages = Object.entries(flattenObject(errorMessages ?? {}));

    const length = messages.length;

    return messages.map(([key, value]) => `${length > 1 ? "- " : ""}${value} (${key})`).join("\n");
  },
};

export default Format;
