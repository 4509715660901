import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import { TrackedLink } from "Components/Common/Links";
import Logo from "Components/Common/Logo";
import { useProfileQuery } from "Domain/Profile";
import { isEventMarketOnly } from "Domain/Profile/Utils";
import { VERSION } from "Settings";

const StyledMenu = styled.div<{ collapsed: boolean }>`
  background: ${(props) => props.theme.white};

  ${tw`shadow-lg`}
  > div {
    background: ${(props) => props.theme.white};
  }

  a {
    font-family: Source Sans Pro;
    font-style: normal;
    color: ${(props) => props.theme.gray["400"]};
    font-weight: 600;
    height: 56px;
    padding: 18px 24px;
    font-size: 1rem;
    line-height: 1.5rem;
    ${tw`items-center`}
  }

  nav {
    a {
      ${({ collapsed }) => (collapsed ? tw`justify-center` : tw`justify-start`)}
      ${tw`w-full flex pt-1 pb-1`}
    }

    a:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.gray["200"]};
    }
  }

  a:hover,
  a:focus {
    outline: none;
    color: ${(props) => props.theme.primary.default};

    i {
      background-color: ${(props) => props.theme.primary.default};
    }
  }

  a.active {
    color: ${(props) => props.theme.primary.default};
    border-right: 2px solid ${(props) => props.theme.primary.default};

    i {
      background-color: ${(props) => props.theme.primary.default};
    }
  }

  a.logo {
    ${tw`p-0 h-auto border-0`}
  }

  i {
    margin-right: ${({ collapsed }) => (collapsed ? "unset" : "24px")};
  }
`;

const StyledIco = styled.i<{ name: string }>`
  background-color: ${({ theme }) => theme.gray["400"]};
  height: 20px;
  width: 20px;

  mask-image: url(/assets/svg/ico-${({ name }) => name}.svg);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
`;

interface PropsType {
  collapsed?: boolean;
  onLogout?: () => void;
}

const MenuDesktop = ({ collapsed = false, onLogout }: PropsType) => {
  const { t } = useTranslation("menu");
  const location = useLocation();
  const profileQuery = useProfileQuery();

  return (
    <StyledMenu className="hidden lg:flex shrink-0" collapsed={collapsed}>
      <div className={`flex flex-col ${!collapsed ? "w-80" : "w-20"} fixed h-full shadow-lg`}>
        <div className="flex flex-col h-0 flex-1">
          <div className="flex-1 flex flex-col pt-4 pb-4 overflow-y-auto">
            <div className={`m-auto max-h-8 my-4`}>
              <TrackedLink to="/" className="logo">
                <Logo className="w-40" mini={collapsed} />
              </TrackedLink>
            </div>

            <nav className="flex-1 bg-white mt-20">
              <TrackedLink to="/" className={location.pathname === "/" ? "active" : ""}>
                <StyledIco name="home" />

                {!collapsed && <span className="block">{t("menu:menu.home")}</span>}
              </TrackedLink>

              {profileQuery.isSuccess && profileQuery.data.interventionsStats.total > 0 ? (
                <TrackedLink
                  to={isEventMarketOnly(profileQuery.data?.markets) ? "/interventions/events" : "/interventions"}
                  className={/^\/interventions*/.test(location.pathname) ? "active" : ""}
                >
                  <StyledIco name="intervention" />

                  {!collapsed && <span className="block">{t("menu:menu.interventions")}</span>}
                </TrackedLink>
              ) : null}

              {profileQuery.isSuccess && profileQuery.data.counters.files.total > 0 ? (
                <TrackedLink to="/files" className={/^\/files*/.test(location.pathname) ? "active" : ""}>
                  <StyledIco name="files" />

                  {!collapsed && <span className="block">{t("menu:menu.files")}</span>}
                </TrackedLink>
              ) : null}

              {profileQuery.isSuccess &&
              profileQuery.data.hasInvoicePermission &&
              profileQuery.data.counters.invoices.total > 0 ? (
                <TrackedLink to="/invoices" className={/^\/invoices*/.test(location.pathname) ? "active" : ""}>
                  <StyledIco name="invoice" />

                  {!collapsed && <span className="block">{t("menu:menu.invoices")}</span>}
                </TrackedLink>
              ) : null}

              <TrackedLink to="/contact" className={/^\/contact*/.test(location.pathname) ? "active" : ""}>
                <StyledIco name="contact" />

                {!collapsed && <span className="block">{t("menu:menu.contact")}</span>}
              </TrackedLink>
            </nav>

            <div className="shrink-0 flex flex-col">
              <TrackedLink
                to="/account"
                className={`w-full flex justify-start p-0  ${/^\/account*/.test(location.pathname) ? "active" : ""}`}
              >
                <div className="flex items-center">
                  <StyledIco name="avatar" />

                  {!collapsed && (
                    <div className="ml-3">
                      <p>{t("menu:menu.my_account")}</p>
                    </div>
                  )}
                </div>
              </TrackedLink>

              <TrackedLink
                to="/login"
                onClick={onLogout}
                className={`w-full flex ${collapsed ? "justify-center" : "justify-start"} pt-1 pb-1`}
              >
                <StyledIco name="logout" />

                {!collapsed && <span className="block">{t("menu:menu.logout")}</span>}
              </TrackedLink>

              {process.env.NODE_ENV !== "production" ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    margin: "0.5rem 1rem",
                    color: "#ccc",
                    fontSize: "0.75rem",
                  }}
                >
                  {VERSION}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledMenu>
  );
};

export default MenuDesktop;
