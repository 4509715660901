import { Brand } from "./Brand";
import DefaultTheme from "./DefaultTheme";

/**
 * Customize the default theme with a given Brand.
 *
 * @param brand
 */
export function getCustomTheme(brand: Brand) {
  const theme = { ...DefaultTheme };

  theme.primary = { ...theme.primary, ...brand.colors.primary };
  theme.primary.default = theme.primary["500"];
  theme.primary.light = theme.primary["100"]; // todo tiny difference
  theme.primary.hover = theme.primary["600"];
  theme.primary.pressed = theme.primary["600"]; // todo this is not right, see theme in js (but we have no color match in palette for now)

  theme.button.primary.light = theme.primary.light;
  theme.button.primary.default = theme.primary.default;
  theme.button.primary.hover = theme.primary.hover;
  theme.button.primary.pressed = theme.primary.pressed;

  theme.processing["50"] = theme.primary["50"];
  theme.processing["100"] = theme.primary["100"];
  theme.processing["200"] = theme.primary["600"];
  return theme;
}
