import i18next from "i18next";
import { createContext, ReactNode, useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useStatePersist } from "use-state-persist";

import { API_ENDPOINT } from "Settings";
import {
  AuthenticationContextType,
  AutoLoginFunction,
  AutoLoginOutput,
  CheckPasswordTokenFunction,
  CheckPasswordTokenInput,
  CheckPasswordTokenOutput,
  CheckUsernameFunction,
  CreateOrUpdatePasswordFunction,
  CreateOrUpdatePasswordInput,
  LocaleOutput,
  LoginFunction,
  LoginMutationInput,
  StartIdentityConfirmationFunction,
  StartIdentityConfirmationOutput,
  UseAuthenticationResult,
  UsernameInput,
} from "Types/Authentication";
import { sendAnalyticsEvent } from "Utils/Analytics";

function setLanguageIfAvailable(language?: string) {
  if (language) {
    i18next.changeLanguage(language);
  }
}

function setSuccessfulAuthenticationStatusFromResponseData(
  responseData: { token: string; language?: string; country?: string; username?: string },
  {
    setToken,
    setContactId,
  }: {
    setToken: (value: ((prevState: string) => string) | string) => void;
    setContactId: (value: ((prevState: string | undefined) => string | undefined) | string | undefined) => void;
  },
) {
  setContactId(responseData.username);
  setToken(responseData.token); // this need to be set so that AutologinResolveRedirect.useEffect triggers again
  setLanguageIfAvailable(responseData.language);
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  contactId: undefined,
  isLogged: () => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout(): void {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setContactId(value: ((prevState: string | undefined) => string | undefined) | string | undefined): void {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setToken(value: ((prevState: string) => string) | string): void {},
  token: "",
});

export const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const [token, _setToken] = useStatePersist<string>("token", "");
  const [contactId, setContactId] = useStatePersist<string | undefined>("cid", undefined);

  let _isLogged = !!token;
  const isLogged = () => _isLogged;

  function setToken(newToken) {
    _isLogged = !!newToken;
    _setToken(newToken);
  }

  const logout = () => {
    setContactId("");
    setToken("");
  };

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        setToken,
        contactId,
        setContactId,
        isLogged,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = (): UseAuthenticationResult => {
  const context = useContext(AuthenticationContext);
  const history = useHistory();

  /**
   * Mutation used to auto-login a user, exchanging a jwt token for an action to perform.
   */
  const autoLoginMutation = useMutation<Response, Error, string>((jwt) =>
    fetch(API_ENDPOINT + "user/v1/autologin?token=" + jwt, {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept-Language": i18next.language },
      credentials: "omit", // this is to avoid sending cookies and generating csrf problems if a valid session is available. See #151
    }),
  );

  /**
   * Auto-login a user, using the related mutation (see above). This will return an action to perform (authenticate,
   * login, ...)
   */
  const autoLogin: AutoLoginFunction = (jwt, location, options) => {
    return new Promise((resolve, reject) =>
      autoLoginMutation.mutate(jwt, {
        onSuccess: async (res) => {
          if (res.status === 200) {
            const responseData: AutoLoginOutput = await res.json();
            const { action, username, token, language, country } = responseData;

            context.logout();

            if (options?.onSuccess) {
              options.onSuccess();
            }

            switch (action) {
              case "authenticate":
                const token = responseData.token;
                if (token === undefined) {
                  return reject();
                }
                setSuccessfulAuthenticationStatusFromResponseData({ ...responseData, token }, context);
                if (location) history.push(location);
                return resolve({ action, username, token, language, country });

              case "login":
                history.push("/login", { username, path: location });
                return resolve({ action, username, language, country });

              case "signup":
                history.push("/signup", { username, path: location });
                return resolve({ action, username, language, country });
            }
          } else if (res.status === 401) {
            context.logout();
            history.push("/login", { path: location });
            return reject("Unauthorized");
          }

          return reject();
        },
      }),
    ) as Promise<AutoLoginOutput>;
  };

  /**
   * Mutation used to create or reset password for an user, after confirming identity by following a unique link sent
   * by mail. This mutation will have the mail sent as a side effect.
   */
  const startIdentityConfirmationMutation = useMutation<Response, Error, UsernameInput>((data) =>
    fetch(API_ENDPOINT + "user/v1/start_identity_confirmation", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Language": i18next.language },
      body: JSON.stringify(data),
      credentials: "omit", // this is to avoid sending cookies and generating csrf problems if a valid session is available. See #151
    }),
  );

  /**
   * Starts the "identity confirmation process", using the related mutation (see above). Will send an email to the user.
   */
  const startIdentityConfirmation: StartIdentityConfirmationFunction = (data) => {
    return new Promise<StartIdentityConfirmationOutput>((resolve, reject) => {
      startIdentityConfirmationMutation.mutate(data, {
        onSuccess: async (res) => {
          const responseData = await res.json();
          if (res.status === 200 || res.status === 201) {
            sendAnalyticsEvent("identity_confirmation", "success", { ...data, ...responseData });
            setLanguageIfAvailable(responseData.language);
            resolve(responseData);
          } else {
            sendAnalyticsEvent("identity_confirmation", "error", { ...responseData });
            reject(responseData);
          }
        },
        onError: async () => {
          sendAnalyticsEvent("identity_confirmation", "error");
          reject();
        },
      });
    });
  };

  /**
   * Mutation used to verify a password token, which is the unique token generated by the backend to verify a user's
   * identity (aka access to the user's email address) before allowing to create or reset the user's password.
   */
  const checkPasswordTokenMutation = useMutation<Response, Error, CheckPasswordTokenInput>((data) =>
    fetch(API_ENDPOINT + "user/v1/check_password_token", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Language": i18next.language },
      body: JSON.stringify(data),
      credentials: "omit", // this is to avoid sending cookies and generating csrf problems if a valid session is available. See #151
    }),
  );

  /**
   * Checks the password token (to authorize passord create/reset), using the related mutation (see above).
   */
  const checkPasswordToken: CheckPasswordTokenFunction = (data) => {
    return new Promise<CheckPasswordTokenOutput>((resolve, reject) => {
      checkPasswordTokenMutation.mutate(data, {
        onSuccess: async (res) => {
          if (res.status === 200) {
            const responseData: CheckPasswordTokenOutput = await res.json();
            resolve(responseData);
          } else {
            reject(res);
          }
        },
        onError: async () => {
          reject();
        },
      });
    });
  };

  /**
   * Mutation to check the existence of a user. Will return the "locale" context if it exists (language, country).
   */
  const checkUsernameMutation = useMutation<Response, Error, UsernameInput>((data) =>
    fetch(API_ENDPOINT + "user/v1/check_username", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Language": i18next.language },
      body: JSON.stringify(data),
      credentials: "omit", // this is to avoid sending cookies and generating csrf problems if a valid session is available. See #151
    }),
  );

  /**
   * Checks the existence of a user, using the related mutation (see above).
   */
  const checkUsername: CheckUsernameFunction = (data) => {
    return new Promise<LocaleOutput>((resolve, reject) => {
      checkUsernameMutation.mutate(data, {
        onSuccess: async (res) => {
          if (res.status === 200) {
            const responseData = await res.json();
            setLanguageIfAvailable(responseData.language);
            resolve(responseData);
          } else {
            reject(res);
          }
        },
        onError: async () => {
          reject();
        },
      });
    });
  };

  /**
   * Create or set a new password for a user, by consuming a password token.
   */
  const passwordMutation = useMutation<Response, Error, CreateOrUpdatePasswordInput>((data) =>
    fetch(API_ENDPOINT + "user/v1/password", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Language": i18next.language },
      body: JSON.stringify(data),
      credentials: "omit", // this is to avoid sending cookies and generating csrf problems if a valid session is available. See #151
    }),
  );

  /**
   * Create or update a password for a user, using the related mutation (see above).
   */
  const createOrUpdatePassword: CreateOrUpdatePasswordFunction = (data, options?) => {
    return new Promise<Response>((resolve, reject) => {
      passwordMutation.mutate(data, {
        onSuccess: async (res) => {
          if (res.status === 200 || res.status === 204) {
            if (options && options.onSuccess) {
              options.onSuccess();
            }
            if (res.status === 200) {
              setSuccessfulAuthenticationStatusFromResponseData(await res.json(), context);
              history.push((options && options.next) || "/");
            }
            resolve(res);
          } else {
            reject(res);
          }
        },
        onError: async () => {
          reject();
        },
      });
    });
  };

  /**
   * Mutation used to sign a user in, by checking the credentials and returning an authentication token.
   */
  const loginMutation = useMutation<Response, Error, LoginMutationInput>((data) =>
    fetch(API_ENDPOINT + "user/v1/login", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Language": i18next.language },
      body: JSON.stringify(data),
      credentials: "omit", // this is to avoid sending cookies and generating csrf problems if a valid session is available. See #151
    }),
  );

  /**
   * Logs a user in, using the related mutation (see above). Returns a token that can be used to authenticate further
   * api requests using the Authenticate http header.
   */
  const login: LoginFunction = (data, options?) => {
    return new Promise((resolve, reject) =>
      loginMutation.mutate(data, {
        onSuccess: async (res) => {
          if (res.status === 200 || res.status === 201) {
            if (res.status === 200) {
              setSuccessfulAuthenticationStatusFromResponseData(await res.json(), context);
              history.push((options && options.next) || "/");
            }
            resolve(res);
          } else {
            reject(res);
          }
        },
        onError: async (error) => {
          reject(error);
        },
      }),
    );
  };

  return {
    ...context,
    autoLogin,
    startIdentityConfirmation,
    checkPasswordToken,
    checkUsername,
    createOrUpdatePassword,
    login,
  };
};
