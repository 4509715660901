import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import SynchronizationContext from "Context/SynchronizationContext";
import { REFETCH_INTERVAL } from "Settings";

function useRefreshableQuery<T>(key, callback, options?: any) {
  const [isSynchronized, setIsSynchronized] = useState(true);
  const synchronizationContext = useContext(SynchronizationContext);
  const result = useQuery<T>(key, callback, {
    ...options,
    refetchInterval: !isSynchronized ? REFETCH_INTERVAL : undefined,
  });

  useEffect(() => {
    // synchronization meta from response if available
    const meta = result.isSuccess ? (result.data as any).meta : null;

    if (meta) {
      if (meta.synchronization.fresh && !isSynchronized) {
        setIsSynchronized(true);
      } else if (!meta.synchronization.fresh && isSynchronized) {
        setIsSynchronized(false);
      }
    }
  }, [result.isSuccess, result.data, isSynchronized]);

  useEffect(() => {
    if (options && options.showSynchronizationStatus) {
      synchronizationContext.setIsSynchronized(isSynchronized);

      return () => synchronizationContext.setIsSynchronized(true);
    }
  }, [isSynchronized, options, synchronizationContext]);

  return { ...result, isSynchronized };
}

export default useRefreshableQuery;
