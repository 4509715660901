import { useState } from "react";

import { Modal } from "Components/Dialog/InterventionCancel";
import { Intervention } from "Types/File";

const useDialog = () => {
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const [dialog, setDialog] = useState<{
    modal: Modal;
    fileId: number;
    companyId: number | undefined;
    intervention: Intervention | undefined;
    onClose: any;
  }>();

  const closeDialog = () => {
    setDialogOpened(false);
  };

  const openDialog = (
    modalToOpen: Modal,
    fileId: number,
    companyId?: number,
    intervention?: Intervention,
    onClose?: () => void,
  ) => {
    setDialog({
      modal: modalToOpen,
      fileId: fileId,
      companyId,
      intervention,
      onClose: () => {
        closeDialog();
        onClose && onClose();
      },
    });
    setDialogOpened(true);
  };

  return { openDialog, closeDialog, dialogOpened, dialog };
};

export default useDialog;
