import { CustomEvent, PageViews, DownloadAndOutlink } from "@piwikpro/react-piwik-pro";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { USE_PIWIK_ANALYTICS } from "Settings";

export type AnalyticsContentGroup =
  | "Connexion"
  | "Accueil"
  | "Interventions"
  | "Dossiers"
  | "Factures"
  | "Contact"
  | "Mon Compte"
  | "Réservation";

export type AnalyticsEventGroup =
  | "click"
  | "dashboard"
  | "edit_contact"
  | "end_of_rental"
  | "equipment_moved"
  | "forgot_password"
  | "identity_confirmation"
  | "infinite_scroll"
  | "invoice_claim"
  | "login"
  | "menu"
  | "profile_change"
  | "profile_change_language"
  | "profile_change_password"
  | "signup"
  | "temporarily_close_site";

export type OnClickActionEventType =
  | ""
  | "account"
  | "carousel_next"
  | "carousel_previous"
  | "connection"
  | "contact"
  | "files"
  | "home"
  | "interventions"
  | "interventions_history"
  | "invoices"
  | "quotes"
  | "undefined";

export type AnalyticsEventType = "intent" | "success" | "failure" | "cancel" | "error" | OnClickActionEventType;

export type TrackWithinButtonType = { [key: string]: string; name: AnalyticsEventGroup; type: AnalyticsEventType };

export type AnalyticsIntentName = "";

export const sendRawAnalyticsEvent = (eventName: string, payload: any) => {
  if (window.gtag) {
    window.gtag("event", eventName, payload);
  }
  // We dont use piwik's trackEvent here because we need to send an event before
  // the eventgroup/eventtype concatenation happens.
};

// Piwik.pro uses a [ category / action / name ] logic for custom events
// We map eventGroup to category, eventType to action, and if available payload.piwikDescription to name
export const sendAnalyticsEvent = (
  eventGroup: AnalyticsEventGroup,
  eventType: AnalyticsEventType = "intent",
  payload?: { [k: string]: string },
  onlyPiwik?: boolean,
) => {
  if (!onlyPiwik) {
    sendRawAnalyticsEvent(eventGroup + "_" + eventType, payload || {});
  }
  CustomEvent.trackEvent(eventGroup, eventType, payload ? payload.piwikDescription : "");
};

// TODO: Not used in code. Do we keep it ?
export const sendAnalyticsErrorEvent = (
  name: AnalyticsIntentName,
  err: Error,
  { fatal, ...morePayload }: { fatal?: boolean; morePayload?: any } = {},
) => {
  sendRawAnalyticsEvent("exception", { description: err, fatal: !!fatal, ...(morePayload || {}) });
  CustomEvent.trackEvent("exception", !!fatal ? "fatal" : "not_fatal");
};

export const sendAnalyticsClickEvent = ({
  classes = "",
  outbound = false,
  domain,
  id,
  url,
}: {
  classes?: string;
  domain?: string;
  id?: string;
  outbound?: boolean;
  url: string;
}) =>
  sendRawAnalyticsEvent("click", {
    ...(classes ? { link_classes: classes } : {}),
    ...(domain ? { link_domain: domain } : {}),
    ...(id ? { link_id: id } : {}),
    link_url: url,
    outbound: !!outbound,
  });

export const sendAnalyticsDownloadEvent = (download) => {
  //  file_extension, file_name, link_classes, link_domain, link_id, link_text, link_url

  if (window.gtag) {
    window.gtag("event", "file_download", { file_name: download.filename });
  }
  if (USE_PIWIK_ANALYTICS) {
    DownloadAndOutlink.trackLink(download.url, "download");
  }
};

/**
 * Sends an event to google analytics. This uses `useEffect` to avoid re-triggering events on re-render, the
 * dependencies' values must change to record a new event.
 *
 * Title is split between group and title, group being also used as content group. If an event with
 * { group: "X", title: "Y" } is sent, then the actual `page_title` will be "X > Y" and `page_group` wimm be "X".
 *
 * An optionnal condition can be passed and the event will be recorded only if the condiiton is true. This is mostly
 * used with queries to only record analytics events once the data is ready (`someQuery.isSuccess`). This avoids
 * tracking pages before the user can actually see it, and also avoid missing data for tracking (content title ...).
 *
 * @param group
 * @param title
 * @param condition
 */
export const useAnalyticsPageViewEvent = ({
  group,
  title,
  condition = true,
}: {
  group: AnalyticsContentGroup;
  title: string;
  condition?: boolean;
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (condition) {
      if (window.gtag) {
        window.gtag("event", "page_view", {
          ...(group ? { content_group: group } : {}),
          page_title: [group, title].filter(Boolean).join(" > "),
          page_path: pathname,
        });
      }
      if (USE_PIWIK_ANALYTICS) {
        PageViews.trackPageView([group, title].filter(Boolean).join(" > "));

        // This is no ideal but the following enableLinkTracking method should be called right after the
        // first trackPageView or trackEvent call.
        // See https://developers.piwik.pro/en/latest/data_collection/web/javascript_tracking_client/api.html#enableLinkTracking
        DownloadAndOutlink.enableLinkTracking(true);
      }
    }
  }, [group, title, pathname, condition]);
};

export const useAnalyticsGenericEffect = (name, payload, { condition = true }) => {
  useEffect(() => {
    if (condition) {
      if (window.gtag) {
        window.gtag("event", name, payload);
      }
    }
    if (USE_PIWIK_ANALYTICS) {
      CustomEvent.trackEvent(name, name);
    }
  }, [condition, name, payload]);
};

export const updatePiwikPayloadError = (error, data) => {
  const codes = new Set();
  if (Array.isArray(data)) {
    data.forEach((element) => {
      codes.add(element.code);
    });
  }
  error["piwikDescription"] = codes.size > 0 ? Array.from(codes).join(", ") : data.code;
  return error;
};

export const useAnalyticsInfiniteScrollEvent = (sourcePage: string) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const debounceTrackEvent = debounce(CustomEvent.trackEvent, 300);
  const handleScroll = useCallback(() => {
    const position = window.scrollY;
    const height = window.innerHeight;
    const offset = Math.round((position / height) * 100);
    if (offset - scrollPosition >= 25) {
      const nextOffset = scrollPosition + 25;
      debounceTrackEvent("infinite_scroll", `${nextOffset.toString()}%`, sourcePage);
      setScrollPosition(scrollPosition + 25);
    }
  }, [scrollPosition, debounceTrackEvent, sourcePage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
};
