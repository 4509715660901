export interface Brand {
  name: string;
  title: string;
  phone: string;
  colors: {
    primary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
    };
  };
  images: {
    logo: string;
    miniLogo: string;
    welcome: string;
    booking: string;
    bookingMobile: string;
  };
  features: {
    isGroupLogoShown: boolean;
  };
  urls: {
    marketing: string;
    privacy: string;
  };
}

export const DEFAULT_BRAND: Brand = {
  name: "wcloc",
  title: "WCLoc",
  phone: "+33 800 942 442",
  colors: {
    primary: {
      50: "#E6F3F4",
      100: "#CDE9EA",
      200: "#B3DCDE",
      300: "#80C5C8",
      400: "#4DAEB3",
      500: "#008D94",
      600: "#005358",
    },
  },
  images: {
    logo: "/static/themes/wcloc/wcloc-logo.png",
    miniLogo: "/static/themes/wcloc/wcloc-mini-logo.png",
    welcome: "/static/themes/wcloc/wcloc-welcome.jpg",
    booking: "/static/themes/wcloc/wcloc-booking.jpg",
    bookingMobile: "/static/themes/wcloc/wcloc-booking-mobile.jpg",
  },
  features: { isGroupLogoShown: true },
  urls: {
    marketing: "https://www.wcloc.com/",
    privacy: "https://www.wcloc.com/download/General/FR/Politique_Confidentialite.pdf",
  },
};

export const MOCK_DEFAULT_BRAND = {
  ...DEFAULT_BRAND,
  name: "test-brand",
  title: "Test Brand",
  images: {
    logo: "logo.svg",
    logoMini: "logo-mini.svg",
    welcome: "welcome.jpeg",
    booking: "booking.jpeg",
    bookingMobile: "booking-mobile.jpeg",
  },
};
