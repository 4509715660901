import { BRAND } from "Settings";

interface PropsType {
  className?: string;
  mini?: boolean;
}

const Logo = ({ className, mini }: PropsType) => {
  if (mini) {
    return <img className={className} src={BRAND.images.miniLogo} alt={BRAND.title} style={{ maxWidth: "32px" }} />;
  }

  return <img className={className} src={BRAND.images.logo} alt={BRAND.title} />;
};

export default Logo;
