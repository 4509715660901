import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

import { BRAND } from "Settings";

const StyledHelp = styled.div`
  ${tw`px-8 pb-4`}
  background: ${(props) => props.theme.primary["50"]};

  h3 {
    font-weight: 600;
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: ${(props) => props.theme.primary.default};
    @media (min-width: 1280px) {
      font-size: 24px;
    }
  }

  .icon-button {
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.primary.default};
    border-radius: 40px;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-right: 10px;
  }

  .icon-button:hover {
    background: ${(props) => props.theme.primary["600"]};
  }

  .ico-phone {
    width: 100%;
    height: 100%;
    display: block;
    background: url(/assets/svg/ico-phone-stroke.svg) no-repeat top left;
    background-size: contain;
  }

  &::before {
    ${tw`px-4 py-4`}
    margin-top:-2px;
    content: "";
    height: 24px;
    display: block;
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/assets/svg/dialog-header.svg);
  }
`;

const Help = () => {
  const { t } = useTranslation("login");
  return (
    <StyledHelp>
      <h3>{t("login:helpSection.title")}</h3>
      <div className="flex items-center">
        <a className="icon-button" href={`tel:${t("login:helpSection.phone").replace(/\s/g, "")}`}>
          <i className="ico-phone" />
        </a>
        <div className="grow flex flex-col">
          <p>{t("login:helpSection.text")}</p>
          <span>{BRAND.phone}</span>
        </div>
      </div>
    </StyledHelp>
  );
};

export default Help;
