import React, { createContext, useState } from "react";

export type SynchronizationContextType = {
  isSynchronized: boolean;
  setIsSynchronized: (state: boolean) => void;
};

export interface RefreshableQueryOptions {
  showSynchronizationStatus?: boolean;
}

export const SynchronizationContext = createContext<SynchronizationContextType>({
  isSynchronized: true,
  setIsSynchronized: (state: boolean) => null,
});

export const SynchronizationProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSynchronized, setIsSynchronized] = useState<boolean>(true);

  return (
    <SynchronizationContext.Provider
      value={{
        isSynchronized,
        setIsSynchronized,
      }}
    >
      {children}
    </SynchronizationContext.Provider>
  );
};

export default SynchronizationContext;
