import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

const StyledButton = styled.button`
  ${tw`shadow-md flex justify-center`}
  background: ${(props) => props.theme.white};
  border-radius: 24px;
  width: 50px;
  height: 50px;

  &.large {
    height: 48px;
    width: 48px;
  }

  &.small {
    height: 32px;
    width: 32px;
  }

  &:hover,
  &:focus {
    outline: 0;
  }

  &:active:enabled {
    box-shadow: inset 0px 2px 4px rgba(39, 39, 42, 0.25);
    border: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${(props) => props.theme.gray["50"]};
  }
}
`;

interface PropsType {
  children: React.ReactNode;
  path?: string;
  onClick?: () => void;
  large?: boolean;
  small?: boolean;
  disabled?: boolean;
}

const WhiteRoundedButton = (props: PropsType) => {
  return props.path ? (
    <Link to={props.path}>
      <StyledButton disabled={props.disabled} className={props.large ? "large" : props.small ? "small" : "large"}>
        {props.children}
      </StyledButton>
    </Link>
  ) : (
    <StyledButton
      onClick={props.onClick}
      disabled={props.disabled}
      className={props.large ? "large" : props.small ? "small" : "large"}
    >
      {props.children}
    </StyledButton>
  );
};

export default WhiteRoundedButton;
