import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import moment from "moment";
import { initReactI18next } from "react-i18next";

// French dates
import "moment/locale/fr";
import Format from "./Format";

i18n.on("languageChanged", function (lng) {
  // Initialize locale for date library.
  moment.locale(lng);
  Format.locale(lng);
});

export const commonParameters = {
  ns: ["default"],
  defaultNS: "default",
  returnNull: false,
  returnEmptyString: false,
  fallbackLng: "fr",
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: "all",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      queryStringParams: { v: window.VERSION || "" },
    },
    detection: {
      lookupQuerystring: "lang",
      lookupCookie: "language",
      lookupLocalStorage: "language",
    },
    interpolation: {
      escapeValue: false,
      format(value, format, lng) {
        if (value instanceof Date) {
          if (format === "calendar") {
            return moment(value).calendar();
          }
          return moment(value).format(format);
        }
        return value;
      },
    },
    ...commonParameters,
  });

export default i18n;
