import yn from "yn";

import { Brand } from "Theme";
import getBrandFromEnv from "Theme/getBrandFromEnv";

/**
 * Make sure an endpoint ends with a slash character.
 * @param someUrl
 */
function ensureUrlEndsWithSlash(someUrl: string): string {
  return someUrl.endsWith("/") ? someUrl : someUrl + "/";
}

/**
 * Base API Endpoint for constructiong API URLs.
 */
export const API_ENDPOINT = ensureUrlEndsWithSlash(process.env.REACT_APP_API_ENDPOINT || "/api/");

export const PAGE_SIZE: number =
  window.PAGE_SIZE || (process.env.REACT_APP_PAGE_SIZE ? parseInt(process.env.REACT_APP_PAGE_SIZE) : null) || 10;

export const REFETCH_INTERVAL = 10000;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || window.SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT || window.SENTRY_ENVIRONMENT;
export const GOOGLE_MAPS_APIKEY = process.env.REACT_APP_GOOGLE_MAPS_APIKEY || window.GOOGLE_MAPS_APIKEY;

export const REACT_QUERY_CONFIG = {
  defaultOptions: { queries: { refetchAllOnWindowFocus: true, retry: 0, staleTime: 60000 } },
};

// Google Analytics configuration and feature flag
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || window.GOOGLE_ANALYTICS_ID;
export const USE_GOOGLE_ANALYTICS = GOOGLE_ANALYTICS_ID
  ? !!yn(process.env.REACT_APP_USE_GOOGLE_ANALYTICS || window.USE_GOOGLE_ANALYTICS)
  : false;

// Piwik Pro Analytics configuration and feature flag
export const PIWIK_ANALYTICS_CONTAINERS_ENDPOINT = "https://enygea.containers.piwik.pro";
export const PIWIK_ANALYTICS_ID = process.env.REACT_APP_PIWIK_ANALYTICS_ID || window.PIWIK_ANALYTICS_ID;
export const USE_PIWIK_ANALYTICS = PIWIK_ANALYTICS_ID
  ? !!yn(process.env.REACT_APP_USE_PIWIK_ANALYTICS || window.USE_PIWIK_ANALYTICS)
  : false;

export const VERSION = window.VERSION || "dev";

export const BRAND: Brand = getBrandFromEnv();
