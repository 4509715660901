import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import { MenuDesktop } from "Components/Menu";
import { useAuthentication } from "Context/AuthenticationContext";

interface PropsType {
  children: React.ReactNode;
}

const StyledContainer = styled.div`
  ${tw`relative`}
  background-color: ${({ theme }) => theme.gray["100"]};
  min-height: 100vh;
`;

const TunnelContainer = ({ children }: PropsType) => {
  const { logout } = useAuthentication();
  const history = useHistory();
  const { t } = useTranslation("tunnel");

  const onLogout = () => {
    logout();
    history.push("/login");
  };

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("tunnel:meta.defaultTitle")}</title>
      </Helmet>
      <div className="lg:flex overflow-hidden">
        <MenuDesktop collapsed onLogout={onLogout} />

        <div className="flex flex-col flex-1 overflow-hidden lg:ml-20 min-h-screen">
          <main className="flex flex-col flex-1 relative z-0 focus:outline-none">{children}</main>
        </div>
      </div>
    </StyledContainer>
  );
};

export default TunnelContainer;
