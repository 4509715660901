import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { Button } from "Components/Common/Buttons";
import { Size } from "Types/Size";

export const Title = styled.h2`
  ${tw`font-bold`}

  font-size: 2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.primary["500"]};
`;

interface ErrorBoundaryFallbackProps {
  title: string;
  description: string;
  buttonText: string;
  to: string;
  wrapper: React.FC<any>;
}

const ErrorBoundaryFallback = ({
  buttonText,
  description,
  title,
  to,
  wrapper: Wrapper,
}: ErrorBoundaryFallbackProps) => {
  return (
    <Wrapper>
      <div className="flex flex-col max-h-screen overflow-y-auto m-auto">
        <div className="flex flex-col max-h-screen flex-1 mx-6 mt-6 lg:mx-16 lg:pb-16 items-center justify-center gap-10">
          <Title className="text-center">{title}</Title>
          <div className="text-center text-2xl">{description}</div>
          <Button size={Size.xl} buttonStyle={tw`rounded w-48 font-semibold ml-auto`} secondary type="button" to="/">
            {buttonText}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default ErrorBoundaryFallback;
